#site-footer{
	background: $pink;
	#map{
		background: #f2f2f2;
		iframe{
			height: 400px;
			vertical-align: bottom;
			@media screen and (max-width:$responsive){
				height: 180px;	
			}
		}
	}
	#pageup{
		a{
			padding: 20px 0;
			text-align: center;
			display: block;
			background: rgba(#FFF,.96);
			color: rgba($normal ,.72);
			letter-spacing: .12em;
			@media screen and (max-width:$responsive){
				padding: 15px 0;	
			}
		}
	}
	#meta{
		padding: 80px 0;
		color: #FFF;
		@media screen and (max-width:$responsive){
			padding: 40px 15px;	
		}
		a{
			color: #FFF;
		}
		.fnav{
			text-align: center;
			@media screen and (max-width:$responsive){
				@include flex();
				border: rgba(#FFF, .16) 1px solid;
				border-radius: 4px;
				overflow: hidden;
			}
			li{
				list-style: none;
				display: inline-block;
				@media screen and (max-width:$responsive){
					width: 50%;
					display: block;
					border-top: rgba(#FFF, .16) 1px solid;
					border-right: rgba(#FFF, .16) 1px solid;
					&:nth-child(-n+2){
						border-top: 0;
					}
					&:nth-child(2n+2){
						border-right: 0;
					}
					a{
						display: block;
						padding: 12px 0;
					}
				}
				&:after{
					content: '|';
					display: inline-block;
					margin: 0 16px;
					opacity: .32;
					@media screen and (max-width:$responsive){
						display: none;
					}
				}
				&:last-child{
					&:after{
						display: none;
					}
				}
			}
		}
		small{
			display: block;
			text-align: center;
			margin: 32px 0 0;
			font-size: 11px;
			@media screen and (max-width:$responsive){
				margin: 24px 0 0;
				font-size: 10px;
			}
		}
	}
}