#site-header{
	width: 100%;
	padding: 0 32px;
	height: $pc-head;
	background: #FFF;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1000;
	box-shadow: 0 0 32px rgba(#000,.06);
	@media screen and (max-width:$tab){
		height: $sp-head;	
	}
	@media screen and (max-width:$responsive){
		padding: 0 15px;
	}
	.logo{
		margin: 24px 0 0;
		height: 32px;
		float: left;
		@media screen and (max-width:$tab){
			height: 24px;
			margin: 16px 0 0;
		}
		img{
			height: 100%;
		}
	}
	.gnavi{
		float: right;
		@media screen and (min-width:$responsive + 1px){
			display: block !important;
		}
		@media screen and (max-width:$responsive){
			visibility: hidden;
			float: none;
			position: absolute;
			top: $sp-head;
			left: -15px;
			width: calc(100% + 30px);
			background: #FFF;
			box-shadow: 0 24px 24px rgba(#000,.048);
			&.active{
				visibility: visible;
			}
		}
		ul{
			@media screen and (max-width:$responsive){
				border-top: $border 1px solid;
				padding: 0 0 15px;	
			}
			li{
				float: left;
				list-style: none;
				font-size: 15px;
				margin: 26px 0 0 48px;
				@media screen and (max-width:$tab){
					font-size: 14px;
					margin: 18px 0 0 24px;
				}
				@media screen and (max-width:$responsive){
					margin: 0;
					float: none;
					border-bottom: $border 1px solid;
				}
				a{
					color: $normal;
					@media screen and (max-width:$responsive){
						padding: 15px;
						display: block;
					}
					&:hover{
						color: $pink;
					}
				}
				&:first-child{
					margin-left: 0;
				}
				&.contact{
					margin-top: 15px;
					margin-left: 24px;
					@media screen and (max-width:$tab){
						margin-top: 10px;	
					}
					@media screen and (max-width:$responsive){
						margin-top: 8px;
						margin-left: 15px;
						margin-right: 15px;
					}
					a{
						background: $green;
						border-radius: 2px;
						color: #FFF;
						display: block;
						padding: 11px 32px;
						font-size: 15px;
						font-weight: 700;
						@media screen and (max-width:$tab){
							padding: 6px 24px;
						}
						@media screen and (max-width:$responsive){
							text-align: center;
							font-size: 15px;
							padding: 12px 0;
						}
						i{
							display: inline-block;
							margin: 0 16px 0 0;
						}
						&:hover{
							background: $pink;
						}
					}
				}
				&.tel{
					margin-top: 20px;
					height: 40px;
					@media screen and (max-width:$tab){
						height: 24px;
						margin-top: 18px;
					}
					@media screen and (max-width:$responsive){
						height: auto;
						border: none;
						text-align: center;
						margin-top: 8px;
					}
					img{
						height: 100%;
						@media screen and (max-width:$responsive){
							height: 36px;
						}
					}
				}
			}
		}
	}
	
	.open{
		@media screen and (min-width:$responsive + 1px){
			display: none !important;
		}
		@media screen and (max-width:$responsive){
			display: block;
			position: absolute;
			top: 0;
			right: -15px;
			background: $green;
			width: $sp-head;
			height: $sp-head;
			@include flex();
			align-items: center;
			justify-content: center;
			cursor: pointer;
		}
		&.active{
			.open__icon{
				&:before{
					top: 50%;
					transform: rotate(45deg) scale(1.2);
				}
				&:after{
					bottom: 50%;
					transform: rotate(-45deg) scale(1.2);
				}
				.open__icon__center{
					width: 0;
				}
			}
		}
		&__icon{
			width: 22px;
			height: 13px;
			position: relative;
			&__center,
			&:before,
			&:after{
				content: '';
				width: 100%;
				height: 1px;
				background: #FFF;
				position: absolute;
				top: 0;
				left: 0;
				transition: all 300ms ease-in-out;
			}
			&__center{
				top: 50%;
				transform: translateY(-50%);
			}
			&:after{
				top: auto;
				bottom: 0;
			}
		}
	}
}