.site-index{
	$height: 640px;
	$sp: 220px;
	.firstview{	
		position: relative;
		.concept{
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			color: #FFF;
			z-index: 2;
			@include flex();
			align-items: center;
			justify-content: center;
			text-align: center;
			figure{
				width: 640px;
				@media screen and (max-width:$tab){
					width: 80%;
				}
				img{
					width: 100%;
					@media screen and (max-width:$tab){
						width: 98%;	
					}
				}
			}
		}
		&__bg{
			height: 640px;
			background: url(/images/index/slide03.jpg) no-repeat center / cover;
			@media screen and (max-width:$tab){
				height: 300px;
			}
			@media screen and (max-width:$responsive){
				height: 200px;	
			}
		}
		.swiper-slide{
			height: $height;
			@media screen and (max-width:$tab){
				height: $sp;
			}
		}
		.swiper-slide-active .swipe-bg,
		.swiper-slide-duplicate-active .swipe-bg,
		.swiper-slide-prev .swipe-bg{
			transform: scale(1);
			animation: zoomUp 16s linear 0s 1 normal both; 
		}
		@keyframes zoomUp {
			0% {
				transform: scale(1);
			}
			100% {
				transform: scale(1.08);
			}
		}
		.swipe-bg{
			background-repeat: no-repeat;
			background-position: center;
			background-size: cover;
			position: absolute;
			width: 100%;
			height: 100%;
			@media screen and (max-width:$tab){
				background-position: center top;
			}
		}
	}
		
	.ablitys{
		.ablitylist{
			@include flex();
			li{
				list-style: none;
				width: 31%;
				background: #FFF;
				padding: 24px;
				margin: 32px 0 0;
				border-radius: 4px;
				overflow: hidden;
				position: relative;
				&:nth-child(1),
				&:nth-child(2),
				&:nth-child(3){
					margin-top: 0;
				}
				@media screen and (max-width:$responsive){
					width: 48.5%;
					margin: 0 0 15px;
					padding: 15px;
				}
				@for $i from 1 through 6{
					&:nth-child(#{$i}){
						&:before{
							$size: 36px;
							content: '#{$i}';
							position: absolute;
							top: 0;
							right: 0;
							width: $size;
							height: $size;
							line-height: $size;
							text-align: center;
							background: $pink;
							font-size: 18px;
							font-weight: 700;
							color: #FFF;
							border-radius: 0 0 0 50%;
						}
					}
				}
				figure{
					width: 80px;
					padding: 16px 0 0;
					margin: 0 auto;
					@media screen and (max-width:$responsive){
						width: 64px;	
					}
					img{
						width: 100%;
					}
				}
				p{
                    font-size: 17px;
                    font-weight: 700;
					text-align: center;
					margin: 16px 0 0;
					@media screen and (max-width:$responsive){
						margin: 12px 0 0;
						font-size: 13.5px;
					}
				}
			}
		}
		.txt{
			text-align: center;
			font-size: 16px;
			margin: 40px 0 0;
			@media screen and (max-width:$responsive){
				margin: 8px 15px 0;
				font-size: 15px;	
			}
		}
	}
	
	.about{
		position: relative;
		&:before{
			content: '';
			width: 50%;
			height: 100%;
			background: url("../../../images/index/about_bg.jpg") no-repeat;
			background-size: cover;
			position: absolute;
			top: 0;
			left: 0;
			@media screen and (max-width:$responsive){
				position: relative;
				width: 100%;
				height: 56vw;
				display: block;
			}
		}
		.display{
            &:before,
            &:after{
                position: absolute;
                content: '';
            }
            &:before{
                $size:32px;
                background: url("../../../images/common/h_p_mark.png") no-repeat;
                background-size: $size;
                width: $size;
                height: $size;
                right: 48px;
                top: 0px;
				@media screen and (max-width:$responsive){
					top: -44px;
					right: 40px;
				}
            }
            &:after{
                $size:48px;
                background: url("../../../images/common/l_p_mark.png") no-repeat;
                background-size: $size;
                width: $size;
                height: $size;
                right: 0;
                top: -44px;
				@media screen and (max-width:$responsive){
					top: -80px;	
					right: -8px;
				}
			}
			dl{
				padding-left: calc(50% + 56px);
				@media screen and (max-width:$responsive){
					padding: 0;
					margin: 24px 0 0;
				}
				dt{
					font-size: 22px;
					margin: 0 0 16px;
					@media screen and (max-width:$responsive){
						font-size: 16px;
						margin: 0 0 8px;
					}
				}
				dd{
					text-align: justify;
					&.btnarea{
						margin: 32px 0 0;
						@media screen and (max-width:$responsive){
							margin: 24px 0 0;	
						}
					}
				}
			}
		}
	}
	
	.news{
		.display{
			&.flex{
				@include flex();
			}
		}
        .leftside{
            width: 24%;
			h3{
				line-height: 1;
				font-size: 16px;
				margin: 0 0 16px;
				&:before{
					content: attr(data-eng);
					font-size: 36px;
					display: inline-block;
					margin: 0 12px 0 0;
				}
				@media screen and (max-width:$tab){
					font-size: 22px;
				}
			}
			a{
				display: inline-block;
				font-weight: 700;
				color: $pink;
				font-size: 15px;
				@media screen and (max-width:$tab){
					position: absolute;
					top: 0;
					right: 0;
					font-size: 13px;
				}
				&:hover{
					text-decoration: underline;
				}
				i{
					display: inline-block;
					font-size: 13px;
					margin: 0 0 0 12px;
				}
			}
        }
        ol{
            width: 76%;
            height: 132px;
            padding: 0 40px 0 0;
            overflow: auto;
			@media screen and (max-width:$tab){
				width: 100%;
				height: 164px;
				padding: 15px;
				border: $border 1px solid;
				border-radius: 4px;
			}
            li{
                border-bottom: $border 1px solid;
                padding: 0 0 24px;
                margin: 0 0 24px;
				@media screen and (max-width:$tab){
					margin: 0 0 15px;
					padding: 0 0 15px;
				}
                &:last-child{
                    margin: 0;
                }
                a{
                    @include flex();
                    align-items: center;
                    color: $normal;
					@media screen and (max-width:$tab){
						display: block;
					}
                    &:hover{
                        h4{
                            text-decoration: underline;
                        }
                    }
                }
                time,.category{
                    display: block;
                    font-weight: 600;
					@media screen and (max-width:$tab){
						float: left;
					}
                }
                time{
                    width: 14%;
                    font-size: 12px;
                    background: $pink;
                    color: #FFF;
                    padding: 2px 0;
                    text-align: center;
					@media screen and (max-width:$tab){
						width: 80px;
						font-size: 10px;
						padding: 1px 12px;
						margin: 2px 0 0;
					}
                }
                h4{
                    width: 86%;
                    font-size: 16px;
                    padding: 0 0 0 32px;
					@media screen and (max-width:$tab){
						width: auto;
						font-size: 12px;
						padding: 8px 0 0;
						clear: both;
					}
                }
            }
        }
	}
}
