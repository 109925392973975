@charset "utf-8";

@import "module/reset";
@import "module/module";


/* BASE COLOR
------------------------------------------------------------*/
$normal:#402a2a;
$link:#D02;
$border:#e8e8e8;

$pink:#f29286;
$green:#5ac7cb;
$light: #faf0ee;
$glay: #f4f4f4;

/* SITE SETTING
------------------------------------------------------------*/
$width:1024px;
$pc-head:80px;
$sp-head:60px;

$tab: $width - 1px;
$responsive: 768px;

@media screen and (min-width:$tab + 1px){
	@include default(
		$width, //サイト幅
		14px, //フォントサイズ
		$normal, //フォントカラー
		$pink, //リンクカラー
		#FFF //ボディ背景
	);
}
@media screen and (max-width:$tab){
	@include default(
		auto, //サイト幅
		14px, //フォントサイズ
		$normal, //フォントカラー
		$pink, //リンクカラー
		#FFF //ボディ背景
	);
}

@media screen and (max-width:$responsive){
	@include default(
		auto, //サイト幅
		13px, //フォントサイズ
		$normal, //フォントカラー
		$link, //リンクカラー
		#FFF //ボディ背景
	);
}

@import "common/header";
@import "common/footer";
@import "common/bread";
@import "page/index";
@import "page/sitemap";
@import "page/contact";
@import "page/privacy";
@import "page/news";

#site-wrapper,
#site-footer{
    position: relative;
    top: $pc-head;
   	@media screen and (max-width:$tab){
        top: $sp-head;
    }
}

.site-module{
	&.wrap{
		padding: 88px 0;
		@media screen and (max-width:$tab){
			padding: 64px 40px;	
		}
		@media screen and (max-width:$responsive){
			padding: 32px 15px;	
		}
		&.mid{
			padding: 32px 0;
			@media screen and (max-width:$tab){
				padding: 32px 40px;	
			}
			@media screen and (max-width:$responsive){
				padding: 32px 15px;	
			}
		}
		&.mid-top{
			padding-top: 64px;
			@media screen and (max-width:$tab){
				padding-top: 48px;	
			}
			@media screen and (max-width:$responsive){
				padding-top: 32px;	
			}
		}
		&.no-top{
			padding-top: 0;
		}
		&.no-bottom{
			padding-bottom: 0;
		}
	}
	&.bg{
		background: #f2f2f2;
	}
	&.bg-light{
		background: $light;
	}
	h2.large,
	h3.large{
		@include min();
		text-align: center;
		font-size: 36px;
		letter-spacing: .024em;
		font-weight: 500;
		line-height: 1;
		margin: 0 0 40px;
		@media screen and (max-width:$responsive){
			font-size: 24px;
			margin: 0 0 20px;
		}
		&:after{
			@include font();
			content: attr(data-eng);
			font-size: 13px;
			letter-spacing: .24em;
			font-weight: 600;
			color: $pink;
			margin: 16px 0 0;
			display: block;
			@media screen and (max-width:$responsive){
				font-size: 12px;
				margin: 8px 0 0;
			}
		}
	}
	.btnarea{
		text-align: center;
		margin: 40px 0 0;
		@media screen and (max-width:$responsive){
			margin: 20px 0 0;	
		}
		a{
			background: $green;
			color: #FFF;
			font-size: 17px;
			font-weight: 700;
			min-width: 280px;
			padding: 12px 64px;
			display: inline-block;
			margin: 0 8px;
			border-radius: 999px;
			@media screen and (max-width:$responsive){
				padding: 12px 0;
				text-align: center;
				display: block;
			}
            &:hover{
                background: $pink;
            }
			&.pink{
				background: $pink;
				&:hover{
					background: $green;
				}
			}
			&.one{
				margin: 0;
			}
			&.block{
				display: block;
				min-width: 1px;
				padding: 12px 0;
				margin: 0;
			}
			&.sqr{
				border-radius: 0;
			}

			i{
				margin: 0 0 0 12px;
			}
		}
	}
    h2.borders,
    h3.borders,
    h4.borders{
        border-bottom: $border 1px solid;
		position: relative;
        padding: 12px;
		padding-left: 32px;
        font-size: 22px;
		line-height: 1;
        font-weight: 700;
		margin: 0 0 24px;
		@media screen and (max-width:$responsive){
			font-size: 17px;
			margin: 0 0 20px;
			padding-left: 28px;
		}
		small{
			display: inline-block;
			margin: 0 0 0 20px;
			font-size: 13px;
			@media screen and (max-width:$responsive){
				margin: 0 0 0 15px;
				font-size: 11px;
			}
		}
		&:after{
			content: '';
			background: url("../../images/common/l_p_mark.png") no-repeat center;
			background-size: 20px;
			width: 20px;
			height: 20px;
			position: absolute;
			top: 12px;
			left: 0;
		}
    }
    .table-style{
		border-collapse: collapse;
		border: $border 1px solid;
        width: 100%;
		@media screen and (max-width:$responsive){
			border: $border 1px solid;
			display: block;
			tbody,thead,tr,th,td{
				display: block;
			}
			tr{
				&:last-child{
					td{
						border-bottom: none;
					}
				}
			}
		}
		tbody,
		thead{
			tr{
				th,td{
					padding: 24px;
					border: $border 1px solid;
					vertical-align: middle;
					text-align: left;
					font-size: 15px;
					@media screen and (max-width:$responsive){
						font-size: 13.5px;
						padding: 15px;
						border: none;
						border-bottom: $border 1px solid;
					}
				}
				th{
					font-weight: 700;
					width: 24%;
                    background: $light;
					@media screen and (max-width:$responsive){
						width: 100%;
						border: none;
						border-bottom: $border 1px solid;
					}
				}
			}
		}
    }
    .form-style{
        border-collapse: collapse;
        width: 100%;
		background: #FFF;
		@media screen and (max-width:$responsive){
			&.responsive{
				display: block;
				border: $border 1px solid;
				tbody,thead,tr,th,td{
					display: block;
				}
				tbody,
				thead{
					tr{
						th,td{
							border-bottom: $border 1px solid;
						}
						th{
							width: 100%;
						}
						&:last-child{
							td{
								border-bottom: none;
							}
						}
					}
				}
			}
		}
		&.long{
            &.table-border{
                thead,
                tbody{
                    tr{
                        th{
                            width: 32%;
							@media screen and (max-width:$responsive){
								width: 100%;
							}
                        }
                    }
                }   
            }
		}
		&.table-border{
			border: $border 1px solid;
            thead,
			tbody{
                tr{
                    th,td{
                        padding-left: 24px;
						@media screen and (max-width:$responsive){
							padding-left: 15px;
						}
					}
					th{
						width: 24%;
						@media screen and (max-width:$responsive){
							width: 100%;
						}
					}
				}
			}
		}
        input,
        textarea,
        select,
        option{
            @include font();
            border: none;
            font-size: 15px;
            background: $glay;
            padding: 10px 12px;
            border-radius: 4px;
        }
        input[type=text],
        input[type=email],
        input[type=tel],
        textarea{
            width: 100%;
            min-width: 100%;
            max-width: 100%;
            display: block;
            @include transition(200ms);
            &:focus{
                background: #FFF;
                box-shadow: 0 0 56px rgba(#000,.12);
                transform: scale(1.04);
            }
        }
        textarea{
            height: 200px;
        }
        tbody,
		thead{
            tr{
                th,td{
                    padding: 32px 0;
                    border-bottom: $border 1px solid;
                    vertical-align: middle;
                    text-align: left;
                    font-size: 16px;
					@media screen and (max-width:$responsive){
						font-size: 13.5px;
						padding: 15px;
					}
                }
                th{
                    width: 30%;
					@media screen and (max-width:$responsive){
						width: 100%;
						background: $light;
					}
                    &.hiss{
                        position: relative;
                        top: 0;
                        left: 0;
                        &:after{
                            content: '必須';
                            background: $pink;
                            color: #FFF;
                            display: inline-block;
                            padding: 4px 24px;
                            position: absolute;
                            top: 50%;
                            right: 40px;
                            font-size: 13px;
                            margin-top: -16px;
                            border-radius: 4px;
							@media screen and (max-width:$responsive){
								right: 15px;	
							}
                        }
                    }
					&.custum{
						&:after{
                            content: attr(data-text);
							color: $normal;
							background: darken($light,2%);
							@media screen and (max-width:$responsive){
								background: darken($light,8%);
							}
						}
					}
                }
                td{
                    strong{
                        font-weight: 700;
                    }
                    .gadget{
                        li{
                            list-style: none;
                            margin: 0 0 24px;
                            &:before{
                                content: attr(data-text);
                                display: block;
                                margin: 0 0 4px;
                            }
                            &:last-child{
                                margin: 0;
                            }
                        }
                    }
                    p.alert{
                        margin: 4px;
                        color: $pink;
                        font-size: 13px;
                        font-weight: 700;
                    }
                }
            }
        }
    }
}