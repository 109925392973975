#page-title{
    height: 360px;
    position: relative;
    @include flex();
    align-items: center;
    justify-content: center;
    overflow: hidden;
	@media screen and (max-width:$tab){
		height: 160px;
		padding: 0;
		justify-content: center;
	}
	@media screen and (max-width:$responsive){
		height: 140px;	
	}
    &:before{
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: rgba($normal,.08);
        z-index: 2;
    }
    .bg{
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-position: center;
        background-size: cover;
		@include transition(1400ms,$ease:linear);
    }
    .box{
        color: rgba(#FFF,.96);
		position: relative;
		z-index: 3;
		white-space: nowrap;
		text-align: center;
        line-height: 1;
		transform: translateY(-8px);
		text-shadow: 0 0 32px rgba($normal,.16);
		@media screen and (max-width:$tab){
			text-align: center;
			transform: translateY(0);
		}
        #eng-title{
			@include min();
            content: attr(data-eng);
            font-size: 48px;
			font-weight: 700;
            display: block;
            margin: 0 0 8px;
			@media screen and (max-width:$tab){
				font-size: 24px;
				margin: 0 0 2px;
			}
        }
        h1,p{
            font-size: 20px;
            font-weight: 700;
            transform: translateY(10px);
            @include transition(800ms,$delay:600ms);
			@media screen and (max-width:$tab){
				font-size: 13px;
			}
        }
    }
}

#bread{
    padding: 24px 0;
    overflow: hidden;
    font-size: 13px;
	background: #FFF;
	border-bottom: $border 1px solid;
	@media screen and (max-width:$tab){
		padding: 16px 40px;
		font-size: 11px;
		width: 100%;
		overflow: hidden;
		position: relative;
		&:after{
			content: '';
			width: 40px;
			height: 100%;
			position: absolute;
			top: 0;
			right: 0;
		}
		ol{
			width: 200%;
		}
	}
    a{
        color: $normal;
		@media screen and (max-width:$tab){
			white-space: nowrap;
		}
    }
    i{
        display: inline-block;
        margin: 0 12px 0 0;
        font-size: 11px;
        vertical-align: middle;
        transform: translateY(-1px);
		color: $pink;
    }
    li{
        float: left;
        list-style: none;
        &:after{
            content: "\f054";
            font-family: "Font Awesome 5 Free";
            font-size: 8px;
            font-weight: 900;
            opacity: .4;
            display: inline-block;
            margin: 0 16px;
            transform: translateY(-2px);
        }
        &:last-child{
            &:after{
                display: none;
            }
        }
    }
	@media screen and (max-width:$responsive){
		padding: 8px 15px;
	}
}

