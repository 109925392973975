.site-sitemap{
	.txt{
		text-align: center;
		font-size: 16px;
		@media screen and (max-width:$responsive){
			margin: 0 15px;
			font-size: 13px;
			text-align: justify;
			br{
				display: none;
			}
		}
	}
    ul{
		@extend .clearfix;
        li{
			@include clum(4,23.5%,2%);
			@media screen and (max-width:$responsive){
				float: none;
				width: auto;
				margin: 8px 0 0;
				&:first-child{
					margin: 0;
				}
			}
            a{
                color: $normal;
                background: #FFF;
                border: $border 1px solid;
                border-radius: 6px;
                display: block;
                padding: 12px 0;
                text-align: center;
                font-weight: 700;
				@media screen and (max-width:$responsive){
					font-size: 14px;	
				}
                &:hover{
					border-color: $pink;
                    background: $pink;
                    color: #FFF;
                }
            }
        }
    }
}