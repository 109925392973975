.site-contact{
	.text{
		text-align: center;
		font-size: 16px;
		margin: 0 0 15px;
		@media screen and (max-width:$responsive){
			font-size: 13px;
			margin: 0 0 24px;
			text-align: left;
			br{
				display: none;
			}
		}
		a{
			text-decoration: underline;
		}
	}
	dl.tel{
		text-align: center;
		line-height: 1;
		margin: 0 0 40px;
		border: $pink 2px dashed;
		padding: 32px 0;
		border-radius: 6px;
		@media screen and (max-width:$responsive){
			padding: 24px 15px;
			margin: 0 0 24px;
			border: $pink 1px dashed;
		}
		dt{
			font-size: 18px;
			@media screen and (max-width:$responsive){
				font-size: 16px;	
			}
		}
		dd{
			margin: 8px 0 0;
			a{
				@include min();
				font-size: 48px;
				font-weight: 700;
				color: $pink;
				@media screen and (max-width:$responsive){
					font-size: 32px;	
				}
			}
		}
	}
    .send{
        text-align: center;
        margin: 48px 0 0;
		.formalert{
			color: #D22;
			font-size: 15px;
			margin: 0 0 20px;
			font-weight: 700;
		}
		@media screen and (max-width:$responsive){
			margin: 32px 0 0;	
		}
        input{
            color: #FFF;
            background: $pink;
			border: $pink 1px solid;
            font-size: 20px;
            padding: 16px 96px;
            cursor: pointer;
			display: inline-block;
			border-radius: 999px;
			&:disabled{
				opacity: .12;
				cursor: auto;
			}
			@media screen and (max-width:$responsive){
				display: block;
				width: 100%;
				padding: 16px 0;
			}
        }
    }

}