.site-news{
	.container{
		width: 70%;
		float: left;
	}
	.sidebar{
		width: 30%;
		padding: 0 0 0 56px;
		float: right;
		ol.update{
			li{
				margin: 0 0 16px;
				padding: 0 0 16px;
				border-bottom: $border 1px solid;
				&:last-child{
					margin: 0;
				}
				a{
					display: block;
					color: $normal;
					@extend .clearfix;
				}
				time{
					width: 96px;
					background: $pink;
					color: #FFF;
					padding: 2px 0;
					font-size: 12px;
					text-align: center;
					float: left;
				}
				h4{
					width: calc(100% - 114px);
					float: right;
				}
			}
		}
		.btnarea{
			margin: 24px 0 0;
		}
	}
	
	#pager{
		text-align: center;
		margin: 32px 0 0;
		a,span{
			$size: 30px;
			width: $size;
			height: $size;
			line-height: $size;
			font-weight: 700;
			text-align: center;
			display: inline-block;
			margin: 0 6px;
			border: $pink 1px solid;
		}
		span{
			background: $pink;
			color: #FFF;
		}
	}
	
	ol.listable{
		overflow: auto;
		border: $border 1px solid;
		box-shadow: 0 0 8px rgba(#000,.06);
		li{
			border-bottom: $border 1px solid;
			&:last-child{
				border: none;
			}
			a{
				padding: 24px;
				@include flex();
				align-items: center;
				color: $normal;
				@media screen and (max-width:$responsive){
					display: block;
				}
				&:hover{
					background: $light;
					h4{
						text-decoration: underline;
					}
				}
			}
			time,.category{
				display: block;
				font-weight: 600;
				@media screen and (max-width:$responsive){
					float: left;
				}
			}
			time{
				width: 14%;
				font-size: 12px;
				background: $pink;
				color: #FFF;
				padding: 2px 0;
				text-align: center;
				@media screen and (max-width:$responsive){
					width: 80px;
					font-size: 10px;
					padding: 1px 12px;
					margin: 2px 0 0;
				}
			}
			h3{
				width: 86%;
				font-size: 16px;
				padding: 0 0 0 32px;
				@media screen and (max-width:$responsive){
					width: auto;
					font-size: 12px;
					padding: 8px 0 0;
					clear: both;
				}
			}
		}
	}
	
	.detail{
		font-size: 15px;
		line-height: 2;
		a{
			color: $link;
			text-decoration: underline;
			&:hover{
				color: $green;
			}
		}
		strong{
			font-weight: 700;
		}
	}
}